import {
  Box,
  SimpleGrid,
  Card,
  CardBody,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  StatArrow,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Badge,
  Button,
  HStack,
  useDisclosure
} from '@chakra-ui/react'
import { useState } from 'react'
import PageHeader from '../../components/PageHeader'
import { AddTenantModal } from './components/AddTenantModal'
import TenantActions from './components/TenantActions'

export default function AdminDashboard() {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [tenants] = useState([
    {
      id: '1',
      name: 'Acme Corp',
      plan: 'enterprise',
      status: 'active',
      locations: 12,
      users: 25,
      lastActive: '2024-03-15',
      mrr: 190
    },
    {
      id: '2',
      name: 'TechStart Inc',
      plan: 'professional',
      status: 'active',
      locations: 5,
      users: 10,
      lastActive: '2024-03-15',
      mrr: 89
    },
    {
      id: '3',
      name: 'Local Cafe Chain',
      plan: 'starter',
      status: 'past_due',
      locations: 2,
      users: 3,
      lastActive: '2024-03-14',
      mrr: 49
    }
  ])

  const stats = {
    totalTenants: 156,
    activeTenants: 142,
    totalMRR: 24650,
    mrrGrowth: 12.5
  }

  return (
    <Box>
      <PageHeader
        title="Admin Dashboard"
        subtitle="Manage tenants, subscriptions, and system settings"
      />

      <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} spacing={6} mb={6}>
        <Card>
          <CardBody>
            <Stat>
              <StatLabel>Total Tenants</StatLabel>
              <StatNumber>{stats.totalTenants}</StatNumber>
              <StatHelpText>
                <StatArrow type="increase" />
                23.36%
              </StatHelpText>
            </Stat>
          </CardBody>
        </Card>

        <Card>
          <CardBody>
            <Stat>
              <StatLabel>Active Tenants</StatLabel>
              <StatNumber>{stats.activeTenants}</StatNumber>
              <StatHelpText>
                {((stats.activeTenants / stats.totalTenants) * 100).toFixed(1)}% active
              </StatHelpText>
            </Stat>
          </CardBody>
        </Card>

        <Card>
          <CardBody>
            <Stat>
              <StatLabel>Monthly Recurring Revenue</StatLabel>
              <StatNumber>${stats.totalMRR}</StatNumber>
              <StatHelpText>
                <StatArrow type="increase" />
                {stats.mrrGrowth}%
              </StatHelpText>
            </Stat>
          </CardBody>
        </Card>

        <Card>
          <CardBody>
            <Stat>
              <StatLabel>Avg. Revenue Per Tenant</StatLabel>
              <StatNumber>
                ${(stats.totalMRR / stats.activeTenants).toFixed(2)}
              </StatNumber>
            </Stat>
          </CardBody>
        </Card>
      </SimpleGrid>

      <Card>
        <CardBody>
          <HStack justify="space-between" mb={4}>
            <Box>Tenant Management</Box>
            <Button colorScheme="blue" onClick={onOpen}>
              Add Tenant
            </Button>
          </HStack>

          <Table>
            <Thead>
              <Tr>
                <Th>Tenant</Th>
                <Th>Plan</Th>
                <Th>Status</Th>
                <Th isNumeric>Locations</Th>
                <Th isNumeric>Users</Th>
                <Th>Last Active</Th>
                <Th isNumeric>MRR</Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {tenants.map((tenant) => (
                <Tr key={tenant.id}>
                  <Td fontWeight="medium">{tenant.name}</Td>
                  <Td>
                    <Badge colorScheme={tenant.plan === 'enterprise' ? 'purple' : 'blue'}>
                      {tenant.plan}
                    </Badge>
                  </Td>
                  <Td>
                    <Badge colorScheme={tenant.status === 'active' ? 'green' : 'red'}>
                      {tenant.status}
                    </Badge>
                  </Td>
                  <Td isNumeric>{tenant.locations}</Td>
                  <Td isNumeric>{tenant.users}</Td>
                  <Td>{tenant.lastActive}</Td>
                  <Td isNumeric>${tenant.mrr}</Td>
                  <Td>
                    <TenantActions
                      tenant={tenant}
                      onEdit={() => {/* Handle edit */}}
                    />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </CardBody>
      </Card>

      <AddTenantModal isOpen={isOpen} onClose={onClose} />
    </Box>
  )
}