import {
  Box,
  Card,
  CardBody,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  useDisclosure,
  Switch,
  Badge,
  Text,
  HStack
} from '@chakra-ui/react'
import { useState, useCallback } from 'react'
import { useStripe, useElements } from '@stripe/react-stripe-js'
import { useToast } from '@chakra-ui/react'
import PageHeader from '../../components/PageHeader'
import { EditPlanModal } from './components/EditPlanModal'

export default function Plans() {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [selectedPlan, setSelectedPlan] = useState(null)
  const stripe = useStripe()
  const elements = useElements()
  const toast = useToast()
  const [plans, setPlans] = useState([
    {
      id: 'starter',
      name: 'Starter',
      price: 49,
      active: true,
      features: {
        locations: 3,
        keywords: 100,
        users: 5,
        posts: 50
      },
      limits: {
        gridPoints: 1500,
        searchQueries: 500
      }
    },
    {
      id: 'professional',
      name: 'Professional',
      price: 89,
      active: true,
      features: {
        locations: 10,
        keywords: 500,
        users: 15,
        posts: 200
      },
      limits: {
        gridPoints: 5000,
        searchQueries: 2500
      }
    },
    {
      id: 'enterprise',
      name: 'Enterprise',
      price: 190,
      active: true,
      features: {
        locations: -1,
        keywords: -1,
        users: -1,
        posts: -1
      },
      limits: {
        gridPoints: 20000,
        searchQueries: 10000
      }
    }
  ])

  const handleEditPlan = async (plan) => {
    setSelectedPlan(plan)
    onOpen()
  }

  const handlePlanUpdate = useCallback(async (updatedPlan) => {
    try {
      // Update Stripe product/price
      const response = await fetch('/api/admin/plans/update', {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify({
          planId: updatedPlan.id,
          name: updatedPlan.name,
          price: updatedPlan.price,
          stripePriceId: updatedPlan.stripePriceId,
          features: updatedPlan.features,
          active: updatedPlan.active
        })
      })

      if (!response.ok) {
        const error = await response.json()
        throw new Error(error.message || 'Failed to update plan')
      }

      const updatedPlans = plans.map(p => 
        p.id === updatedPlan.id ? updatedPlan : p
      )
      setPlans(updatedPlans)
      toast({
        title: 'Plan Updated',
        description: 'Plan configuration has been updated successfully',
        status: 'success',
        duration: 5000,
        isClosable: true,
      })

      onClose()
    } catch (error) {
      toast({
        title: 'Error',
        description: error.message || 'Failed to update plan',
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    }
  }, [plans, toast])
  return (
    <Box>
      <PageHeader
        title="Plan Management"
        subtitle="Configure subscription plans and pricing"
      />

      <Card>
        <CardBody>
          <Table>
            <Thead>
              <Tr>
                <Th>Plan Name</Th>
                <Th isNumeric>Price</Th>
                <Th>Status</Th>
                <Th>Features</Th>
                <Th>Limits</Th>
                <Th>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {plans.map((plan) => (
                <Tr key={plan.id}>
                  <Td fontWeight="medium">{plan.name}</Td>
                  <Td isNumeric>${plan.price}</Td>
                  <Td>
                    <Switch 
                      isChecked={plan.active} 
                      colorScheme="green"
                      isDisabled={plan.id === 'enterprise'}
                    />
                  </Td>
                  <Td>
                    <Text>
                      Locations: {plan.features.locations === -1 ? 'Unlimited' : plan.features.locations}
                      <br />
                      Keywords: {plan.features.keywords === -1 ? 'Unlimited' : plan.features.keywords}
                      <br />
                      Users: {plan.features.users === -1 ? 'Unlimited' : plan.features.users}
                    </Text>
                  </Td>
                  <Td>
                    <Text>
                      Grid Points: {plan.limits.gridPoints}/mo
                      <br />
                      Search Queries: {plan.limits.searchQueries}/mo
                    </Text>
                  </Td>
                  <Td>
                    <Button
                      size="sm"
                      onClick={() => handleEditPlan(plan)}
                      isDisabled={plan.id === 'enterprise'}
                    >
                      Edit
                    </Button>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </CardBody>
      </Card>

      <EditPlanModal
        isOpen={isOpen}
        onClose={onClose}
        plan={selectedPlan}
      />
    </Box>
  )
}