import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  useToast,
  Icon
} from '@chakra-ui/react'
import { FaEllipsisV, FaUserSecret, FaBan, FaEdit } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../../hooks/useAuth'

interface TenantActionsProps {
  tenant: {
    id: string
    name: string
    status: string
  }
  onEdit: () => void
}

export default function TenantActions({ tenant, onEdit }: TenantActionsProps) {
  const navigate = useNavigate()
  const toast = useToast()
  const { impersonateTenant } = useAuth()

  const handleImpersonate = async () => {
    try {
      await impersonateTenant(tenant.id)
      toast({
        title: 'Tenant Access Granted',
        description: `You are now viewing as ${tenant.name}`,
        status: 'success',
        duration: 5000,
        isClosable: true,
      })
      navigate('/locations') // Redirect to tenant's dashboard
    } catch (error) {
      toast({
        title: 'Error',
        description: error.message || 'Failed to access tenant account',
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    }
  }

  return (
    <Menu>
      <MenuButton
        as={IconButton}
        icon={<FaEllipsisV />}
        variant="ghost"
        size="sm"
        aria-label="Tenant actions"
      />
      <MenuList>
        <MenuItem onClick={handleImpersonate} icon={<Icon as={FaUserSecret} />}>
          View as Tenant
        </MenuItem>
        <MenuItem onClick={onEdit} icon={<Icon as={FaEdit} />}>
          Edit Tenant
        </MenuItem>
        {tenant.status === 'active' && (
          <MenuItem 
            icon={<Icon as={FaBan} />}
            color="red.500"
          >
            Suspend Tenant
          </MenuItem>
        )}
      </MenuList>
    </Menu>
  )
}