import {
  Card,
  CardBody,
  VStack,
  FormControl,
  FormLabel,
  Input,
  Button,
  Text,
  Alert,
  AlertIcon,
  useToast,
  Code,
  Box,
  HStack,
  Switch,
  FormHelperText
} from '@chakra-ui/react'
import { useState } from 'react'

export default function GoogleApiSettings() {
  const [apiKey, setApiKey] = useState('')
  const [isEnabled, setIsEnabled] = useState(false)
  const [oauthClientId, setOauthClientId] = useState('')
  const [oauthClientSecret, setOauthClientSecret] = useState('')
  const toast = useToast()

  const handleSave = async () => {
    try {
      const response = await fetch('/api/admin/settings/google-api', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify({
          apiKey,
          oauthClientId,
          oauthClientSecret,
          enabled: isEnabled
        })
      })

      if (!response.ok) {
        throw new Error('Failed to save API key')
      }

      toast({
        title: 'Settings Saved',
        description: 'Google API configuration has been updated successfully',
        status: 'success',
        duration: 5000,
        isClosable: true,
      })
    } catch (error) {
      toast({
        title: 'Error',
        description: error.message || 'Failed to save settings',
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    }
  }

  return (
    <Card>
      <CardBody>
        <VStack spacing={6} align="stretch">
          <Text fontSize="lg" fontWeight="bold">Google API Configuration</Text>
          
          <Alert status="info">
            <AlertIcon />
            <VStack align="start" spacing={2}>
              <Text>
                Configure a custom Google API key to use instead of the default plan-based keys.
                This will override the API keys provided in subscription plans.
              </Text>
              <Text fontSize="sm">
                Required APIs:
                - Maps JavaScript API
                - Places API
                - Business Profile API
              </Text>
            </VStack>
          </Alert>

          <FormControl display="flex" alignItems="center">
            <FormLabel mb="0">
              Use Custom API Key
            </FormLabel>
            <Switch
              isChecked={isEnabled}
              onChange={(e) => setIsEnabled(e.target.checked)}
            />
          </FormControl>

          <FormControl isDisabled={!isEnabled}>
            <FormLabel>Google API Key</FormLabel>
            <Input
              value={apiKey}
              onChange={(e) => setApiKey(e.target.value)}
              placeholder="Enter your Google API key"
              type="password"
            />
            <FormHelperText>
              Your API key will be encrypted before storage
            </FormHelperText>
          </FormControl>

          <FormControl isDisabled={!isEnabled}>
            <FormLabel>OAuth Client ID</FormLabel>
            <Input
              value={oauthClientId}
              onChange={(e) => setOauthClientId(e.target.value)}
              placeholder="Enter your OAuth Client ID"
              type="password"
            />
          </FormControl>

          <FormControl isDisabled={!isEnabled}>
            <FormLabel>OAuth Client Secret</FormLabel>
            <Input
              value={oauthClientSecret}
              onChange={(e) => setOauthClientSecret(e.target.value)}
              placeholder="Enter your OAuth Client Secret"
              type="password"
            />
          </FormControl>

          {isEnabled && (
            <Box p={4} bg="gray.50" borderRadius="md">
              <Text fontWeight="medium" mb={2}>Testing Instructions:</Text>
              <Code p={3} display="block" whiteSpace="pre-wrap">
                {`1. Enable required APIs in Google Cloud Console
2. Create credentials with appropriate restrictions
3. Configure OAuth consent screen
4. Create OAuth 2.0 Client ID credentials
5. Test the API key with a sample request
6. Monitor usage in Google Cloud Console`}
              </Code>
            </Box>
          )}

          <HStack justify="flex-end">
            <Button
              colorScheme="blue"
              onClick={handleSave}
              isDisabled={isEnabled && !apiKey}
            >
              Save Changes
            </Button>
          </HStack>
        </VStack>
      </CardBody>
    </Card>
  )
}