import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Switch,
  useToast
} from '@chakra-ui/react'
import { useState, useEffect } from 'react'

interface EditPlanModalProps {
  isOpen: boolean
  onClose: () => void
  plan: any
}

export function EditPlanModal({ isOpen, onClose, plan }: EditPlanModalProps) {
  const [formData, setFormData] = useState({
    name: '',
    price: 0,
    active: true,
    features: {
      locations: 0,
      keywords: 0,
      users: 0,
      posts: 0
    },
    limits: {
      gridPoints: 0,
      searchQueries: 0
    }
  })
  const toast = useToast()

  useEffect(() => {
    if (plan) {
      setFormData(plan)
    }
  }, [plan])

  const handleSubmit = async () => {
    try {
      // API call to update plan
      toast({
        title: 'Plan Updated',
        description: 'Plan configuration has been updated successfully',
        status: 'success',
        duration: 5000,
        isClosable: true,
      })
      onClose()
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to update plan',
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    }
  }

  if (!plan) return null

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit Plan: {plan.name}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={4}>
            <FormControl>
              <FormLabel>Plan Name</FormLabel>
              <Input
                value={formData.name}
                onChange={(e) => setFormData({ ...formData, name: e.target.value })}
              />
            </FormControl>

            <FormControl>
              <FormLabel>Monthly Price ($)</FormLabel>
              <NumberInput
                value={formData.price}
                onChange={(value) => setFormData({ ...formData, price: parseInt(value) })}
                min={0}
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </FormControl>

            <FormControl display="flex" alignItems="center">
              <FormLabel mb="0">Active</FormLabel>
              <Switch
                isChecked={formData.active}
                onChange={(e) => setFormData({ ...formData, active: e.target.checked })}
              />
            </FormControl>

            <FormControl>
              <FormLabel>Location Limit</FormLabel>
              <NumberInput
                value={formData.features.locations}
                onChange={(value) => setFormData({
                  ...formData,
                  features: { ...formData.features, locations: parseInt(value) }
                })}
                min={-1}
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </FormControl>

            <FormControl>
              <FormLabel>Keyword Limit</FormLabel>
              <NumberInput
                value={formData.features.keywords}
                onChange={(value) => setFormData({
                  ...formData,
                  features: { ...formData.features, keywords: parseInt(value) }
                })}
                min={-1}
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </FormControl>

            <FormControl>
              <FormLabel>User Limit</FormLabel>
              <NumberInput
                value={formData.features.users}
                onChange={(value) => setFormData({
                  ...formData,
                  features: { ...formData.features, users: parseInt(value) }
                })}
                min={-1}
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </FormControl>

            <FormControl>
              <FormLabel>Monthly Grid Points</FormLabel>
              <NumberInput
                value={formData.limits.gridPoints}
                onChange={(value) => setFormData({
                  ...formData,
                  limits: { ...formData.limits, gridPoints: parseInt(value) }
                })}
                min={0}
                step={100}
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </FormControl>

            <FormControl>
              <FormLabel>Monthly Search Queries</FormLabel>
              <NumberInput
                value={formData.limits.searchQueries}
                onChange={(value) => setFormData({
                  ...formData,
                  limits: { ...formData.limits, searchQueries: parseInt(value) }
                })}
                min={0}
                step={100}
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </FormControl>
          </VStack>
        </ModalBody>

        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button colorScheme="blue" onClick={handleSubmit}>
            Save Changes
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}