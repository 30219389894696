import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  VStack,
  useToast
} from '@chakra-ui/react'
import { useState } from 'react'

interface AddTenantModalProps {
  isOpen: boolean
  onClose: () => void
}

export function AddTenantModal({ isOpen, onClose }: AddTenantModalProps) {
  const [formData, setFormData] = useState({
    companyName: '',
    adminEmail: '',
    adminFirstName: '',
    adminLastName: '',
    plan: 'professional'
  })
  const toast = useToast()

  const handleSubmit = async () => {
    try {
      // API call to create tenant
      toast({
        title: 'Tenant Created',
        description: 'New tenant account has been created successfully',
        status: 'success',
        duration: 5000,
        isClosable: true,
      })
      onClose()
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to create tenant',
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add New Tenant</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={4}>
            <FormControl isRequired>
              <FormLabel>Company Name</FormLabel>
              <Input
                value={formData.companyName}
                onChange={(e) => setFormData({ ...formData, companyName: e.target.value })}
              />
            </FormControl>

            <FormControl isRequired>
              <FormLabel>Admin Email</FormLabel>
              <Input
                type="email"
                value={formData.adminEmail}
                onChange={(e) => setFormData({ ...formData, adminEmail: e.target.value })}
              />
            </FormControl>

            <FormControl isRequired>
              <FormLabel>Admin First Name</FormLabel>
              <Input
                value={formData.adminFirstName}
                onChange={(e) => setFormData({ ...formData, adminFirstName: e.target.value })}
              />
            </FormControl>

            <FormControl isRequired>
              <FormLabel>Admin Last Name</FormLabel>
              <Input
                value={formData.adminLastName}
                onChange={(e) => setFormData({ ...formData, adminLastName: e.target.value })}
              />
            </FormControl>

            <FormControl isRequired>
              <FormLabel>Subscription Plan</FormLabel>
              <Select
                value={formData.plan}
                onChange={(e) => setFormData({ ...formData, plan: e.target.value })}
              >
                <option value="starter">Starter</option>
                <option value="professional">Professional</option>
                <option value="enterprise">Enterprise</option>
              </Select>
            </FormControl>
          </VStack>
        </ModalBody>

        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button colorScheme="blue" onClick={handleSubmit}>
            Create Tenant
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}