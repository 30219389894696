import {
  Box,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  useToast
} from '@chakra-ui/react'
import PageHeader from '../../components/PageHeader'
import GoogleApiSettings from './components/GoogleApiSettings'

export default function AdminSettings() {
  const toast = useToast()

  return (
    <Box>
      <PageHeader 
        title="Admin Settings"
        subtitle="Configure system-wide settings and integrations"
      />

      <Tabs>
        <TabList>
          <Tab>Google API</Tab>
          <Tab>System</Tab>
          <Tab>Security</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <GoogleApiSettings />
          </TabPanel>
          <TabPanel>
            System settings coming soon...
          </TabPanel>
          <TabPanel>
            Security settings coming soon...
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  )
}