const VITE_GOOGLE_MAPS_API_KEY = import.meta.env.VITE_GOOGLE_MAPS_API_KEY || process.env.VITE_GOOGLE_MAPS_API_KEY
const NODE_ENV = import.meta.env.MODE || 'development'
const VITE_APP_URL = import.meta.env.VITE_APP_URL || 'http://localhost:3000'
const VITE_API_URL = import.meta.env.VITE_API_URL || 'https://api.trackandboost.com'
const DATABASE_URL = process.env.DATABASE_URL || import.meta.env.DATABASE_URL
const REDIS_URL = process.env.REDIS_URL || import.meta.env.REDIS_URL

if (!VITE_GOOGLE_MAPS_API_KEY) {
  console.warn('Google Maps API key is not configured')
}

if (!DATABASE_URL) {
  console.warn('Database URL is not configured')
}

if (!REDIS_URL) {
  console.warn('Redis URL is not configured')
}

export const config = {
  google: {
    apiKey: VITE_GOOGLE_MAPS_API_KEY,
    mapsLibraries: ['places', 'geometry', 'drawing', 'marker'],
    mapId: undefined,
    allowedOrigins: [VITE_APP_URL, 'https://stackblitz.com']
  },
  app: {
    url: VITE_APP_URL,
    api: VITE_API_URL
  },
  db: {
    url: DATABASE_URL,
    poolMin: parseInt(import.meta.env.DATABASE_POOL_MIN || '2'),
    poolMax: parseInt(import.meta.env.DATABASE_POOL_MAX || '10')
  },
  redis: {
    url: REDIS_URL,
    password: import.meta.env.REDIS_PASSWORD
  },
  isDevelopment: NODE_ENV === 'development',
  isProduction: NODE_ENV === 'production',
  isTest: NODE_ENV === 'test',
} as const

export type Config = typeof config
