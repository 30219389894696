import { createContext, useContext, useEffect, useState, useRef } from 'react'
import { Loader } from '@googlemaps/js-api-loader'
import { config } from '../config'

interface GoogleMapsContextType {
  isLoaded: boolean
  loadError: Error | null
  google: typeof google | null
}

const GoogleMapsContext = createContext<GoogleMapsContextType>({
  isLoaded: false,
  loadError: null,
  google: null
})

export function useGoogleMaps() {
  const context = useContext(GoogleMapsContext)
  if (!context) {
    throw new Error('useGoogleMaps must be used within a GoogleMapsProvider')
  }
  return context
}

interface GoogleMapsProviderProps {
  children: React.ReactNode
}

export function GoogleMapsProvider({ children }: GoogleMapsProviderProps) {
  const [isLoaded, setIsLoaded] = useState(false)
  const [loadError, setLoadError] = useState<Error | null>(null)
  const [google, setGoogle] = useState<typeof window.google | null>(null)
  const loaderRef = useRef<Loader | null>(null)

  useEffect(() => {
    if (!config.google.apiKey) {
      setLoadError(new Error('Google Maps API key is not configured'))
      return
    }

    if (window.google?.maps) {
      setGoogle(window.google)
      setIsLoaded(true)
      return
    }

    if (loaderRef.current) {
      return
    }

    loaderRef.current = new Loader({
      apiKey: config.google.apiKey,
      version: 'weekly',
      libraries: config.google.mapsLibraries
    })

    loaderRef.current.load()
      .then((google) => {
        setGoogle(google)
        setIsLoaded(true)
      })
      .catch((error) => {
        setLoadError(error)
      })

    return () => {
      loaderRef.current = null
      setIsLoaded(false)
      setGoogle(null)
    }
  }, [config.google.apiKey])

  return (
    <GoogleMapsContext.Provider value={{ 
      isLoaded, 
      loadError, 
      google
    }}>
      {children}
    </GoogleMapsContext.Provider>
  )
}